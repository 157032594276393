.toolbar-wrapper {
  @apply bg-gray-50;
  @apply w-full;
  @apply p-2 mb-4;
  @apply rounded-md;
}

button.toolbar {
  @apply justify-center;
  @apply rounded-md;
  @apply bg-gray-200;
  @apply px-3 py-1;
  @apply text-sm font-semibold leading-6 text-gray-700;
  @apply shadow-sm;
  @apply hover:bg-gray-300;
  @apply focus-visible:outline;
  @apply focus-visible:outline-2;
  @apply focus-visible:outline-offset-2;
  @apply focus-visible:outline-gray-600;
  @apply w-32;
}
