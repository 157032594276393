#datepicker select {
  min-width: 55px;
  background-position: right center;
}

#YearSelector_start,
#YearSelector_end {
  width: 75px;
}

/* they dont support indigo pallet so we flip purple classes */
#datepicker .bg-purple-600 {
  @apply bg-indigo-600;
}

#datepicker .bg-purple-500 {
  @apply bg-indigo-500;
}

#datepicker .bg-purple-50 {
  @apply bg-indigo-50;
}

#datepicker .bg-purple-100 {
  @apply bg-indigo-100;
}

#datepicker .text-purple-800 {
  @apply text-indigo-800;
}
