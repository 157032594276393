@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 1em;
}

@layer base {
  a {
    @apply font-semibold leading-6 text-indigo-600 hover:text-indigo-500;
  }

  button.standard,
  button[type='button'].standard {
    @apply justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
  }

  button:disabled.standard,
  button[disabled].standard,
  button:disabled.standard.secondary,
  button[disabled].standard.secondary,
  button:disabled.standard.small,
  button[disabled].standard.small {
    @apply bg-indigo-400 text-white shadow-sm;
  }

  button.standard.secondary,
  button[type='button'].standard.secondary {
    @apply bg-gray-200  text-gray-700  hover:bg-gray-100  border-gray-300 border;
  }

  button.standard.small,
  button[type='button'].standard.small {
    @apply py-1 px-1.5 leading-6;
  }

  button.danger,
  button[type='button'].danger {
    @apply justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600;
  }

  label.selector {
    @apply relative flex h-5 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-4 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-indigo-500 peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-indigo-500;
  }

  .diff-viewer pre {
    @apply text-sm;
  }

  button.help {
    @apply ml-4 justify-center rounded-md bg-gray-200 px-3 py-1 text-base font-semibold leading-6 text-gray-700 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 h-9 focus-visible:outline-offset-2 focus-visible:outline-gray-600 w-12;
  }
}

/* TAGS  */
.rti--container,
.rti--container:focus-within {
  border: 0 !important;
  box-shadow: none !important;
  display: inline !important;
}

/* DATABLES */
.datatable > div {
  display: block;
}

.rdt_Pagination select {
  background-image: none;
  height: 20px;
  padding-top: 2px;
}

.kanit-bold {
  font-family: 'Kanit', sans-serif;
  font-weight: 700;
  font-style: normal;
}
