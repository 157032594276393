.promptly__single-value,
.promptly__control,
.promptly__option {
  @apply !text-sm;
  @apply !cursor-pointer;
}

.promptly__control {
  box-shadow: none !important;
  @apply !border-gray-300;
}

.promptly__control--is-focused {
  box-shadow: none !important;
  @apply !border-indigo-600;
}

.promptly__option--is-selected {
  @apply !bg-indigo-600;
  @apply !text-white;
}

.promptly__option--is-focused {
  @apply !bg-indigo-400;
  @apply !text-white;
}

.promptly__input:focus {
  @apply !shadow-none;
  @apply !ring-transparent;
}

.promptly__menu-list,
.promptly__menu-portal {
  z-index: 9999 !important;
}
