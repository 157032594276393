/* make code editor compatible with tailwinds  */
.w-tc-editor > textarea,
.w-tc-editor-preview {
  @apply !text-base;
}

.prompt-editor .token.punctuation {
  @apply !text-indigo-700;
}

.prompt-editor .token.variable {
  @apply bg-indigo-400 rounded-sm !text-white !inline;
}

.prompt-editor .token.block {
  display: inline;
}

.prompt-editor {
  @apply !text-gray-900;
}

.composer-highlighter pre {
  @apply h-full;
  @apply !bg-white;
  @apply h-[calc(100%-30px)];
  @apply text-sm;
  @apply !overflow-x-hidden;
  @apply !not-italic;
}

.playground-messages .promptly__control {
  @apply bg-gray-50;
}
